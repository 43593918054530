import React, {useRef} from 'react'
import {SaJooRecord} from "../../store/records/types";
import SaJooRecordContainer from "./sajoo-record-container";
import {AppState} from "../../store/AppState";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {fetchSaJooRecord} from "../../store/records/action";
import {CircularProgress} from "@material-ui/core";
import {SAJOO_PRICE} from "../../constants";

interface Props {
  isLoading: boolean;
  saJooRecords: SaJooRecord[]
  replayBtnClick: (code: string) => void
}


const ReplayContainer: React.FC<Props> = ({isLoading, saJooRecords, replayBtnClick}) => {
  const codeInput = useRef<HTMLInputElement>(null)

  return (
    <div className='container mx-auto max-w-screen-md p-4  mt-16'>
      <h1>다시 보기</h1>
      <p className="mb-0">구매하셨던 내역에 대해서 30일간 다시 보기를 제공해드립니다.</p>

      <h2 className="mt-12">구매 목록</h2>
      <p className='text-xs text-gray-500'>
        구매 코드는 고객님의 단말(PC 혹은 스마트폰)에 저장됩니다.
        시크릿 모드 상태에서 구매하셨다면 구매 코드가 저장되지 않습니다.
        혹은 웹 브라우저의 사용 기록을 삭제하시면 단말에 저장된 구매 코드가 사라집니다.
        만약 구매 코드가 사라졌다면 아래 구매 코드 직접 입력란에 직접 입력하여 다시 보실 수 있습니다.
      </p>

      {saJooRecords.length === 0 &&
      <div className="mt-4">
        단말에 저장된 구매 내역이 없습니다.
      </div>}
      {
        saJooRecords.map((val, i) => (
          <SaJooRecordContainer isLoading={isLoading} saJooRecord={val} key={i} fetchSaJooRecord={() => replayBtnClick(val.code)}/>
        ))
      }

      <h2 className="mt-12">구매 코드 직접 입력</h2>
      <p className='text-xs text-gray-500'>
        단말에 저장된 구매 코드가 삭제된 경우 아래 입력란에 구매 코드를 직접 입력하시어 다시 보실 수 있습니다.
      </p>
      <div className="flex justify-between items-center mt-4">
        <input placeholder="구매 코드"
               className="h-8 ring-1 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 block w-full pl-7 pr-12 sm:text-sm rounded-md bold"
               type="text"
               ref={codeInput}
        />
        <button className='ml-2 mx-auto w-24 h-8 text-xs  bg-blue-400 hover:bg-blue-500 rounded-md text-white'
                onClick={() => {
                  if (!isLoading && codeInput.current && !(codeInput.current.value.trim() === '')) {
                    replayBtnClick(codeInput.current.value.trim())
                  }
                }
                }
        >
          {isLoading ? (
            <CircularProgress color={'inherit'} size={18} disableShrink/>
          ) : (
            `다시보기`
          )}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({records,sajoo}: AppState) => (
  {
    isLoading: sajoo.isLoading,
    saJooRecords: records.saJooRecords
  }
)

const mapDispatchToProps = (dispatch: Dispatch) => (
  {
    replayBtnClick: (code: string) => dispatch(fetchSaJooRecord(code))
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(ReplayContainer)