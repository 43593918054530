import React from 'react'
import {SaJooRecord} from "../../store/records/types";
import {EumYang} from "../../enums/EumYang";
import moment from "moment-timezone"
import {CircularProgress} from "@material-ui/core";

interface Props {
  isLoading: boolean
  saJooRecord: SaJooRecord
  fetchSaJooRecord: () => void
}


const SaJooRecordContainer: React.FC<Props> = ({isLoading, saJooRecord, fetchSaJooRecord}) => {
  const {code, due, owner, receipt_url} = saJooRecord
  const m = moment.tz(due, "Asia/Seoul");
  return (
    <div className="mt-8 border-l-4 border-blue-600 pl-3">
      <div className="flex justify-start items-center">
        <div className="bold ">구매 코드 {code}</div>
        <a href={receipt_url} target="_blank" className="text-xs bold text-blue-400 pl-4 cursor-pointer">영수증 보기</a>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <div className="bold text-xs text-gray-500 mt-2">사주 풀이</div>

          <p className="my-0 bold text-sm">
            {`${owner.sex === EumYang.YANG ? "남자" : "여자"} ${owner.calendar === EumYang.YANG ? "양력" : "음력"} ${owner.year}년 ${owner.month}월 ${owner.day}일 ${owner.hour}시 ${owner.minute}분`}
          </p>
          <p className="text-xs text-red-500 my-0 font-bold">
            {m.format("YYYY년 MM월 DD일 HH시 mm분")} 만료
          </p>
        </div>

        <button className='w-20 py-2 text-xs  bg-blue-400 hover:bg-blue-500 rounded-md text-white'
                onClick={() => {
                  if (!isLoading) {
                    fetchSaJooRecord()
                  }
                }}>
          {isLoading ? (
            <CircularProgress color={'inherit'} size={18} disableShrink/>
          ) : (
            `다시보기`
          )}
        </button>

      </div>
    </div>
  )
}

export default SaJooRecordContainer